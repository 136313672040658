import React from "react"
import "./communitybus.scss"
import { createMarkup } from "../../../utils"

export default function CommunityBusFirstComponent(props) {
  return (
    <>
      <section className="f1-block bus">
        <div className="container">
          <div className="col-group">
            <div className="col-dt-8">
              <div className="img-wrapper">
                <img src={props.data.featuredImage?.node?.localFile?.publicURL} alt={props.data.title} />
              </div>
            </div>
            <div className="col-dt-4 right-col">
              <div className="text-wrapper h2" dangerouslySetInnerHTML={createMarkup(props.data.content)}>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
