import * as React from "react"
import "./calltoactionbanner.scss"
import {Link} from "gatsby"

export default function CallToActionBanner(props) {

  return (
    <>
      <section className="call-to-action">
        <div className="container">
            <div className="call-to-action-wrapper">
                <div className="white-space"/>
                <div className="col-group">
                    <div className="col-dt-6 first-col">
                        <div className="title h2">{props?.data?.title}</div>
                        <div className="desc">{props?.data?.description}</div>
                    </div>
                    <div className="col-dt-6 second-col">
                        <div className="button-wrapper">
                            <div className="apply-btn">
                            <a href={props?.data?.buttonLink} target="_blank" rel="noopener noreferrer">
                            {props?.data?.buttonText}
                            </a>

                            </div>
                            <div className="logo">
                            <img src="/images/call-to-action/logo.svg" alt="Logo" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}
