import * as React from "react"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import WhoWeAre from "../components/WhoWeAre"
import { graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import ComboWrapperCommunityBus from "../components/ComboWrapperCommunityBus"
import CommunityBusFirstComponent from "../components/CommunityBusFirstComponent"
import CallToActionBanner from "../components/CallToActionBanner"
import CallToActionBannerCIC from "../components/CallToActionBannerCIC"

export default function CommunityBus(pageData) {
  const data = pageData?.data
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
        <div className="communtity-bus-who-we-are">
        <WhoWeAre
          title={data.wpPage.pageSubtitle?.subtitle}
          name={data.wpPage?.title}
          description={data.wpPage.pageDescription?.pageDescription}
        />
        </div>
        <CommunityBusFirstComponent data={data.wpPage} />
        <CallToActionBannerCIC />
        <ComboWrapperCommunityBus data={data?.wpPage?.communityBus} locale={locale}/>
       
      </Layout>
    </>
  )
}

export const pageQuery = (graphql` query communityBusData($databaseId:Int) {
    wpPage(databaseId: {eq: $databaseId}) {
      id
      title
      pageSubtitle {
        fieldGroupName
        subtitle
      }
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      pageDescription {
        fieldGroupName
        pageDescription
      }
      communityBus{
        communityBusGallery {
          localFile {
            publicURL
          }
          title
        }
        communityBusVideo {
          communityBusVideo {
            localFile {
              publicURL
            }
            title
            mimeType
            description
          }
          videoLink
        }
        communityBusTiming{
          dayAndTime
          time
        }
        locationLink
        locationName
        message
        locationLatitude
        locationLongitude
      }
     
    }
  }
  
  `)