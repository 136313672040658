import React, { useEffect, useContext, useState } from "react"
import ReactModal from 'react-modal'
import "./comboWrapperCommuntityBus.scss"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import SliderNavigation from '../SharedComponents/SliderNavigation'

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import GoogleMapReact from 'google-map-react'

import { createMarkup } from "../../../utils"

export default function ComboWrapperCommunityBus(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  const handleApiLoaded = (map, maps) => {
    new maps.Marker({      //Create a new marker and use the map's center as the location.
      position: {
        lat: parseFloat(props.data.locationLatitude),
        lng: parseFloat(props.data.locationLongitude)
      },
      map: map,
      title: 'Marker!'
    });
    var bounds = new maps.LatLngBounds();
    bounds.extend({
      lat: parseFloat(props.data.locationLatitude),
      lng: parseFloat(props.data.locationLongitude)
    });
    map.setCenter(bounds.getCenter());

map.fitBounds(bounds);

//remove one zoom level to ensure no marker is on the edge.
map.setZoom(11);
  };
 
 
 
  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      videos_ar:translateString(language: AR, string: "VIDEOS")
      videos_en:translateString(language: EN, string: "VIDEOS")
      gallery_ar:translateString(language: AR, string: "GALLERY")
      gallery_en:translateString(language: EN, string: "GALLERY")
      where_find_ar:translateString(language: AR, string: "موقع المركز")
      where_find_en:translateString(language: EN, string: "Where You Can Find US")
      working_hours_ar:translateString(language: AR, string: "ساعات العمل")
      working_hours_en:translateString(language: EN, string: "WORKING HOURS")
      our_location_ar:translateString(language: AR, string: "موقعنا")
      our_location_en:translateString(language: EN, string: "OUR LOCATION")
      link_portal_ar:translateString(language: AR, string: "المسار الرياضي/الغدير   ←")
      link_portal_en:translateString(language: EN, string: "SBF HQ Al Ghadir   →")
      sunday_time_ar:translateString(language: AR, string: "الاحد 5م – 11م")
      sunday_time_en:translateString(language: EN, string: "Sunday  5PM- 11PM")
      monday_time_ar:translateString(language: AR, string: "الاثنين 5م – 11م")
      monday_time_en:translateString(language: EN, string: "Monday  5PM- 11PM")
      tuesday_time_ar:translateString(language: AR, string: "الثلاثاء 5م – 11م")
      tuesday_time_en:translateString(language: EN, string: "Tuesday  5PM- 11PM")
      wednesday_time_ar:translateString(language: AR, string: "الاربعاء 5م – 11م")
      wednesday_time_en:translateString(language: EN, string: "Wednesday  5PM- 11PM")
      thursday_time_ar:translateString(language: AR, string: "الخميس 5م – 11م")
      thursday_time_en:translateString(language: EN, string: "Thursday  5PM- 11PM")
      eid_holiday_ar:translateString(language: AR, string: "نهنئكم بعيد الأضحى المبارك ونحيطكم علمًا بأن مركز التواصل المجتمعي سيتوقف عن العمل خلال إجازة العيد وذلك بدءًا من 24 يونيو 2023 وحتى 4 يوليو 2023.")
      eid_holiday_en:translateString(language: EN, string: "We wish you a blessed Eid and would like to inform you that the Community Information Centre will be closed during the Eid holiday starting from 24 June 2023 to 4 July 2023.")
    }
  }`)

  const swiperRef = React.useRef(null)
  const [isOpen, setOpen] = useState(false)

  const handleModalOpen = event => {

    setOpen(true)
  }
  const shouldRenderArrows=false;
  if(props.data.communityBusGallery!=null){
    const shouldRenderArrows = props.data.communityBusGallery.length > 1
  }
  

  const handleModalClose = event => {
    setOpen(false)
  }

  ReactModal.setAppElement('#___gatsby')
  let showModal = true

  return (
    <>
      <section className="combo-wrapper bus-combo">
        <div className="container">
          <hr className="hrline"></hr>
          <div className="combo-box-tab">
            <ul className="tabs tabs-list h2 buscombolist" data-controls="#drf">
            {props.data.communityBusVideo?.videoLink ? (
              <li data-tab="#videos">
                {string_translation(stringTranslations, "videos", locale)}
              </li>
            ):''}
             {props.data.communityBusGallery ? (
               <li data-tab="#image-gallery">
               {string_translation(stringTranslations, "gallery", locale)}
             </li>
            ):''}
             
              <li className="active"  data-tab="#the-map">
                {string_translation(stringTranslations, "where_find", locale)}
              </li>
             
            </ul>
          </div>
          <div className="tab-container" id="drf">
            <div className="resizing" id="videos">
              <div className="video-wrapper">
                <video autoPlay playsInline muted loop>
                  <source
                    src={
                      props.data.communityBusVideo?.communityBusVideo?.localFile
                        ?.publicURL
                    }
                    type={props.data.communityBusVideo?.mimeType}
                  />
                </video>
                {props.data.communityBusVideo?.videoLink ? (
                  <div>
                    <ReactModal
                      isOpen={isOpen}
                      onRequestClose={handleModalClose}
                      
                    >
                      <div className="modal-video-movie-wrap">
                        <button class="modal-video-close-btn"  onClick={handleModalClose}></button>
                        <iframe width="460" height="230" src={"https://www.youtube.com/embed/"+props.data.communityBusVideo?.videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    </ReactModal>

                    <div className="play-btn" onClick={() => setOpen(true)}>
                      <img src="/images/play-btn.svg" alt="Play" />
                    </div>
                    <div className="spinner">
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="col-group">
                <div className="col-dt-2"></div>
                <div className="col-dt-4">
                  <div className="title">
                    {props.data?.communityBusVideo?.title}
                  </div>
                </div>
                <div className="col-dt-6">
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={createMarkup(
                      props.data.communityBusVideo?.description
                    )}
                  ></div>
                </div>
              </div>
            </div>
            {props.data.communityBusGallery ? (   <div className="resizing" id="image-gallery">
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination]}
                pagination={{ clickable: true }}
                id="design-hub-img-gallery"
                spaceBetween={5}
                slidesPerView={1}
                slidesPerGroup={1}
                grabCursor={"true"}
                ref={swiperRef}
                dir={locale === "en" ? "ltr" : "rtl"}
              >
                {props.data.communityBusGallery.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="img-wrapper">
                      <img src={image?.localFile?.publicURL} alt={image.title} />
                    </div>
                  </SwiperSlide> 
                ))}

                {shouldRenderArrows && <SliderNavigation ref={swiperRef} />}

              </Swiper>
            </div> ):''}
            <div className="active resizing" id="the-map">
              <div className="img-wrapper map bustimingblock">

              
                  <div className="col-dt-6">
                  <h2 className="subheadlocation">    {string_translation(stringTranslations, "our_location", locale)}</h2>
                    <hr className="hrlinesmall" />
                    <div className="mapbox col-dt-6">
                    <GoogleMapReact
  bootstrapURLKeys={{ key: 'AIzaSyBImgE5xrlL6vlN-XMjRErx2Dk9LNEKPvg' }}
  defaultCenter={{
    lat: 24.7136,
    lng: 46.6753
  }}
  defaultZoom={9}
  yesIWantToUseGoogleMapApiInternals
  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
>
</GoogleMapReact></div>
<div className="col-dt-6 buslink">
<a class="click-link buslinkspace" href={props.data.locationLink} target="_blank" rel="noopener noreferrer">{props.data.locationName}</a>
</div>
                  </div>
                  <div className="col-dt-6">
                <h2 className="subheadlocation">  {string_translation(stringTranslations, "working_hours", locale)}</h2>
                  <hr className="hrlinesmall" />
                  <table className="tablebus"  >
                  {props.data.communityBusTiming.map (({ dayAndTime,time }) => (
                    <tr>
                      <td className="bustablecolumnday" width="50%">{dayAndTime}</td>
                      <td className="bustablecolumntime" >{time}</td>
                    </tr>
                      ))}
                    
                  </table>
                  </div>
               
         
        
                             
              </div>
            </div>
         
          </div>
        
        </div>
      </section>
    </>
  )
}
