import * as React from "react"
import "./calltoactionbanner.scss"
import { createMarkup, string_translation } from "../../../utils"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function CallToActionBannerCIC() {
  const {
    locale,
    originalUrl, // ar
  } = useContext(TranslateUrlsContext)
  const { stringTranslations } = useStaticQuery(graphql`
  query {
    stringTranslations {
      sbcommunity_ar: translateString(language: AR, string: "فعاليات مجتمع المسار الرياضي")
      sbcommunity_en: translateString(language: EN, string: "Sports Boulevard Community Activities")
      view_ar: translateString(language: AR, string: "عرض")
      view_en: translateString(language: EN, string: "VIEW")
    }
  }
`)
  return (
    <>
      <section className="call-to-action-cic">
        <div className="container">
            <div className="call-to-action-wrapper">
                <div className="white-space"/>
                <div className="col-group">
                    <div className="col-dt-6 first-col">
                        <div className="title h2">{string_translation(
                                stringTranslations,
                                "sbcommunity",
                                locale
                              )}</div>
                             <div className="desc">&nbsp;</div>
                    </div>
                    <div className="col-dt-6 second-col">
                        <div className="button-wrapper">
                            <div className="apply-btn">
                            <a href={"cic-activity"}  rel="noopener noreferrer">
                            {string_translation(
                                stringTranslations,
                                "view",
                                locale
                              )}
                            </a>

                            </div>
                            <div className="logo">
                            <img src="/images/call-to-action/logo.svg" alt="Logo" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}
